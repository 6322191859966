.scanQrWrapper,
.imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.scanQrWrapper {
    .imageContainer {
        position: relative;
        width: 100%;
        height: auto;
        gap: 1rem;

        .canvasContainer {
            background-color: gray;
            width: fit-content;
            height: 100%;

            canvas {
                max-width: 100%;
                height: 40vh;
            }
        }
    }

    .buttonsContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}