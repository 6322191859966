//
// NoUiSlider
//

// Base
.noUi-target {
  border: 0;
  background: if(isDarkMode(), $gray-200, $gray-100);
  box-shadow: none;

  &.noUi-horizontal {
    height: 15px;

    .noUi-handle {
      width: 24px;
      height: 24px;
      top: -4.5px;
      border-radius: 50%;
      outline: none;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  &.noUi-vertical {
    height: 150px;
    width: 15px;

    .noUi-handle {
      width: 24px;
      height: 24px;
      right: -4.5px;
      border-radius: 50%;
      outline: none;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }

  .noUi-connect {
    background: $component-active-bg;
  }

  .noUi-handle {
    border: 1px solid $gray-200;
    box-shadow: 0 3px 6px -3px rgba(#000, 0.7);
  }

  // Small size
  &.noUi-sm {
    @include nouislider-size(6px, 20px, 20px, 7px);
  }

  // Large size
  &.noUi-lg {
    @include nouislider-size(18px, 30px, 30px, 6px);
  }
}

// Theme colors
@each $name, $value in $theme-colors {
  // NoUiSlider success color option
  .noUi-target.noUi-target-#{$name} {
    .noUi-connects {
      background-color: theme-light-color($name);

      .noUi-connect {
        background-color: $value;
      }
    }

    .noUi-handle {
      border: 1px solid $value;
      box-shadow: 0 3px 6px -3px rgba($value, 0.7);
      background-color: $value;
    }
  }
}

// Tooltip
.noUi-tooltip {
  box-shadow: $tooltip-box-shadow;
  background: $tooltip-bg;
  color: $tooltip-color;
  font-size: $tooltip-font-size;
  border: 0;
  padding: 0.5rem 0.75rem;
  @include border-radius($tooltip-border-radius);
}
